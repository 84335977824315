<template>
  <div class="accountList">
    <v-list
      ref="list"
      :isAdd="true"
      :isManySelect="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :extraParams="extraParams"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->

        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="登录账号" v-model="searchParam.account" />
        <v-input label="用户名" v-model="searchParam.realName" />
        <v-input label="手机号" v-model="searchParam.mobile" />
      </template>
      <template #operateSlot="scope">
        <v-button
          :text="scope.row.status == 1 ? '关闭' : '开启'"
          type="text"
          @click="toClose(scope.row)"
        />
        <v-button
          text="编辑"
          type="text"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          @click="toEdit(scope.row)"
        />
        <v-button
          text="密码重置"
          type="text"
          @click="batchLineSend(scope.row, 2)"
        />
        <v-button
          text="删除"
          type="text"
          @click="batchLineSend(scope.row, 1)"
        />
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量删除"
          :disabled="scope.selectedData.data.length === 0"
          @click="batchSend(scope.selectedData, 1)"
        ></v-button>
        <v-button
          text="批量重置"
          :disabled="scope.selectedData.data.length === 0"
          @click="batchSend(scope.selectedData, 2)"
        ></v-button>
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getAccountListUrl,
  deleteAccountUrl,
  setAccountUrl,
  setAccountStatusUrl,
} from "./api.js";
import { accountSts } from "./map";

export default {
  name: "accountList",
  data() {
    return {
      searchParam: {
        account: null,
        realName: null,
        mobile: null,
      },
      extraParams: {},
      tableUrl: getAccountListUrl,
      headers: [
        {
          prop: "loginName",
          label: "登录账号",
        },
        {
          prop: "userName",
          label: "用户姓名",
        },
        {
          prop: "roleName",
          align: "center",
          label: "所属角色",
        },
        {
          prop: "mobileNum",
          align: "center",
          label: "手机号码",
        },
        {
          prop: "status",
          align: "center",
          label: "当前状态",
          formatter: (row, prop) => {
            for (let i = 0; i < accountSts.length; i++) {
              if (row.status === Number(accountSts[i].value)) {
                return accountSts[i].label;
              }
            }
          },
        },
      ],
    };
  },
  mounted() {},
  methods: {
    toAdd() {
      this.$router.push({
        name: "accountAdd",
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "accountAdd",
        query: { id: data.id, communityId: data.communityId },
      });
    },
    toDelete(item) {
      let params = {
        ids: item.id,
      };
      this.$axios.post(`${deleteAccountUrl}?ids=${item.id}`).then((res) => {
        if (res.code === 200) {
          this.$refs.list.search();
        } else {
        }
      });
    },
    toClose(item) {
      this.$confirm("是否确定更改?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          status: item.status == 0 ? 1 : 0,
          id: item.id,
        };
        this.$axios.post(`${setAccountStatusUrl}`, params).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          } else {
          }
        });
      });
    },
    batchLineSend(row, type) {
      let id = row.id;
      this.send(id, type);
    },
    batchSend(list, type) {
      console.log("list--", list);
      const { data, ids } = list;
      let id = ids.join(",");
      this.send(id, type);
    },
    send(id, type) {
      this.$confirm("是否确定操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params;
        if (type == 1) {
          params = {};
        } else if (type == 2) {
          params = {
            userIds: id,
          };
        }
        let url;
        if (type == 1) {
          url = `${deleteAccountUrl}?ids=${id}`;
        } else if (type == 2) {
          url = setAccountUrl;
        }
        this.$axios.post(`${url}`, params).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          } else {
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.accountList {
  box-sizing: border-box;
  height: 100%;
}
</style>
